import axios from 'axios';

const orderApi: any = {}

orderApi.getCurrency = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/cstnl/currency', {params})
}

orderApi.addAddress = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/webcst/address', params)
}
orderApi.getAddressList = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/address', {params})
}
orderApi.delAddress = (params: {}): Promise<{}> => {
  return axios.delete('/v1/api/webcst/address', {data: params})
}
orderApi.modifyAddress = (params: {}): Promise<{}> => {
  return axios.put('/v1/api/webcst/address', params)
}
orderApi.getAddressDtl = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/address/detail', {params})
}
orderApi.addInvoice = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/webcst/invoice', params)
}
orderApi.getInvoicePoint = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/invoice/config', {params})
}
orderApi.modifyInvoice = (params: {}): Promise<{}> => {
  return axios.put('/v1/api/webcst/invoice', params)
}
orderApi.getInvoiceDtl = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/invoice/detail', {params})
}
orderApi.getInvoiceList = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/invoice', {params})
}
orderApi.delInvoice = (params: {}): Promise<{}> => {
  return axios.delete('/v1/api/webcst/invoice', {data: params})
}
orderApi.addCart = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/webcst/cart', params)
}
orderApi.getCartList = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/cart', {params})
}
orderApi.updateCart = (params: {}): Promise<{}> => {
  return axios.put('/v1/api/webcst/cart', params)
}
orderApi.submitOrder = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/webcst/order', params)
}
orderApi.getOrderList = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/inquiry/list2', {params})
}
orderApi.getOrderDtl = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/inquiry/detail', {params})
}
orderApi.modifyOrderDtl = (params: {}): Promise<{}> => {
  return axios.put('/v1/api/webcst/inquiry/update', params)
}
orderApi.submitOtherOrder = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/webcst/order/check', params)
}

orderApi.getDownInquiry = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/repair/order', {params})
}
orderApi.addDownInquiry = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/webcst/repair/order', params)
}
orderApi.editDownInquiry = (params: {}): Promise<{}> => {
  return axios.put('/v1/api/webcst/repair/order', params)
}
orderApi.getCustomerDtl = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/repair/shop', {params})
}
orderApi.setCustomerDtl = (params: {}):Promise<{}> => {
  return axios.put('/v1/api/webcst/repair/shop', params)
}
orderApi.identifyAddress = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/address/identify', {params})
}
orderApi.identifyInvoice = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/invoice/identify', {params})
}
orderApi.getPayImg = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/pay', {params})
}
orderApi.getPayStatus = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/pay/status', {params})
}

orderApi.getTicketData = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/mini/ticket', params)
}

orderApi.ShortUrl = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/common/short_url', params)
}
orderApi.getPaySrc = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/pay/native', {params})
}
orderApi.getNewPaySrc = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/pay/hf', {params})
}
orderApi.getGoodsId = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/web/parts/search/info/goods', {params})
}


// company
orderApi.getCompanyInfo = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/repair_shop/info', {params})
}
orderApi.updateCompanyInfo = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/webcst/repair_shop/info', params)
}
orderApi.companyExists = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/cst/repair_shop/exists', {params})
}


orderApi.getUserList = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/client', {params})
}
orderApi.addUser = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/webcst/client', params)
}
orderApi.updateUser = (params: {}): Promise<{}> => {
  return axios.put('/v1/api/webcst/client', params)
}
orderApi.updateVerifyStatus = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/webcst/client/verify', params)
}
orderApi.updateStatus = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/webcst/user/status/update', params)
}
orderApi.updateUserCompany = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/webcst/user/company/change', params)
}
orderApi.getJobsList = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/repair_shop/job', {params})
}
orderApi.updateJobs = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/webcst/repair_shop/job', params)
}
orderApi.assignment = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/webcst/repair_shop/change/admin', params)
}
orderApi.getCompanyType = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/repair_shop/category/list', {params})
}
orderApi.checkCompany = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/repair_shop/exists', {params})
}


orderApi.getAppid = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/pay/config', {params})
}
orderApi.getIdentifyInfo = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/cstnl/license/img/identify/check', {params})
}

orderApi.getOrderCount = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/inquiry/count', {params})
}


// 应付单
orderApi.getCopewithList = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/statement/pay/list', {params})
}
orderApi.getCopewithDetail = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/statement/pay/details', {params})
}
orderApi.getCopewithPayhf = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/cstnl/statement/pay/hf', params)
}
orderApi.getCopewithPayhfStatus = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/statement/pay/status', {params})
}
orderApi.statementPayOffline = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/webcst/statement/pay/offline', params)
}

// 对账单
orderApi.getStatementList = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/statement/list', {params})
}


// 售后
orderApi.getRefundList = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/refund/list', {params})
}
orderApi.getRefundListCount = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/refund/count', {params})
}
orderApi.getRefundDetail = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/refund/details', {params})
}
orderApi.getRefundReasons = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/refund/reasons', {params})
}
orderApi.applyRefuse = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/webcst/refund/apply', params)
}
orderApi.cancelRefuse = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/webcst/refund/cancel', params)
}

// 反馈
orderApi.feedback = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/webcst/feedback', params)
}


// 
orderApi.getDistributorInfo = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/distributor/info', {params})
}

orderApi.getDistributorUnit = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/web/goods/distributorUnit', {params})
}
orderApi.applyOrderInvoice = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/webcst/inquiry/invoice/apply', params)
}
orderApi.getInvoiceHisList = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/invoice/history/list', {params})
}
orderApi.confirmReceive = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/webcst/inquiry/receive', params)
}
orderApi.applyStateInvoice = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/webcst/statement/invoice/apply', params)
}
orderApi.getUnderlinePayInfo = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/merchant/pay/user/info', {params})
}
orderApi.getPaypalInfo = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/pay/paypal', {params})
}




export default orderApi