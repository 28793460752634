import React, { useContext, useEffect, useReducer, useState } from "react";
import { ConfigProvider, Button, notification } from 'antd'
import Header from "./Header";
import Footer from "./Footer";
import Sider from './Sider';
import styles from './index.module.scss'
import Router from "../../Routes"
import MainContext from '../../stores/stores'
import accountApi from "../../api/accountApi";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { AliveScope } from 'react-activation'
import axios from "axios";
import { useIntl } from 'react-intl'

const reducer = (state: any, action: any) => {
  switch(action.type) {
    case 'userInfo':
      return {
        ...state,
        userInfo: action.value
      };
    case 'areaOptions':
      return {
        ...state,
        areaOptions: action.value
      };
    case 'cartNum':
      return {
        ...state,
        cartNum: action.value
      }
    case 'lang': 
      return {
        ...state,
        lang: action.value
      }
    case 'pageType': 
      return {
        ...state,
        pageType: action.value
      }
    case 'logo': 
      return {
        ...state,
        logo: action.value
      }
    case 'isWomala': 
      return {
        ...state,
        isWomala: action.value
      }
    case 'webData': 
      return {
        ...state,
        webData: action.value
      }
    case 'host': 
      return {
        ...state,
        host: action.value
      }
    case 'crumb':
      return {
        ...state,
        crumb: action.value
      }
    case 'vinData':
      return {
        ...state,
        vinData: action.value
      }
    case 'colorPrimary': 
      return {
        ...state,
        colorPrimary: action.value
      }
    case 'canLang': 
      return {
        ...state,
        canLang: action.value
      }
		case 'open_new': 
			return {
				...state,
				open_new: action.value
			}
		case 'isCst':
			return {
				...state,
				isCst: action.value
			}
		case 'distributorInfo':
			return {
				...state,
				distributorInfo: action.value
			}
    default:
      return state
  }
}

const Main: React.FC<any> = (props) => {
  const intl = useIntl()
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate()
  const [store, setStore] = useReducer(reducer, {
    userInfo: {},
    areaOptions: [],
    pageType: localStorage.getItem("style") || '2',
    logo: '',
    isWomala: false,
    webData: {},
    host: '',
    crumb: JSON.parse(window.sessionStorage.getItem('crumb') || '[]'),
    vinData: {},
    colorPrimary: localStorage.getItem("colorPrimary") || '#434343',
    canLang: [],
		open_new: '1',
		isCst: 0,
		distributorInfo: {}
  })
  const { pageType, colorPrimary } = store
  
  const { pathname } = useLocation()
  const [ searchParams, setSearchParams ] = useSearchParams()
  let vinkey = searchParams.get('vinkey') || ""

  const token = localStorage.getItem("token") || ""
  let other = ['/class', '/vin', '/code', '/carsearch/list', '/morepage']
  useEffect(() => {

    let host = window.location.host
    setStore({ value: host, type: 'host' })
    if(host.includes('womala')) {
      setStore({ value: true, type: 'isWomala' })
    }
    getSysiting()
    getHomeData()
    if(token) {
      getUserInfo()
			getIsCst()
			getDistributorInfo()
      fetch('https://xqpstatics.007vin.com/pca.json')
      .then(response => response.json())
      .then(data => {
        setStore({value: data, type: 'areaOptions'})
      })
    }
  },[])
	
	const getDistributorInfo = () => {
		accountApi.getDistributorInfo().then((res: any) => {
			if(res.code === 1) {
				setStore({value: res.data, type: 'distributorInfo'})
			}
		})
	}
	
	const getIsCst = () => {
	  accountApi.getIsCst().then((res: any) => {
	    if(res.code === 1) {
				setStore({value: res.data.is_cst, type: 'isCst'})
	    }
	  })
	}
	const getHomeData = () => {
    let lang = localStorage.getItem('lang') || undefined
    accountApi.getHomeDataThree({ 
      domain: window.location.host,
      lang
    }).then((res: any) => {
      if(res.code === 1) {
        let contract2 = res.data && res.data.contract2 ? res.data.contract2 : {}
        if(lang) {
          let item = contract2[lang] ? contract2[lang] : {}
          setStore({ value: item, type: 'webData' })
        }
      }
    })
  }
	
  const getSysiting = () => {
    let params = {
      lang: localStorage.getItem('lang') || undefined,
      domain: window.location.host
    }
    accountApi.getSysiting(params).then((res: any) => {
      if(res.code === 1) {
        let localstyle = localStorage.getItem("style")
        if(!localstyle || (res.data.style !== localstyle)) {
          localStorage.setItem('style', res.data.style || '2')
          setStore({ value: res.data.style || '2', type: 'pageType' })
        }
        setStore({ value: res.data.logo, type: 'logo' })
        document.title = res.data.name || ''
        if(res.data.icon) {
          let icon = document.createElement("link")
          icon.rel = 'icon'
          icon.href = res.data.icon
          document.getElementsByTagName('head')[0].appendChild(icon)
        }
				setStore({ value: res.data.open_new ? res.data.open_new : '1', type: 'open_new' })
        // setStore({ value: res.data, type: 'webData' })
        setStore({ value: res.data.languages, type: 'canLang' })
        
        setStore({ value: res.data.color || '#434343', type: 'colorPrimary' })
        localStorage.setItem("colorPrimary", res.data.color || '#434343')
        // document.getElementsByTagName("body")[0].style.setProperty('--test', '#898734')
      }
    })
  }
  const getUserInfo = () => {
    accountApi.getUserInfo().then((res: any) => {
      if(res.code === 1) {
        res.data.company = res.data.reg_company_name
        getIm(res.data.uid)
        localStorage.setItem('uid', res.data.uid)
        setStore({value: res.data, type: 'userInfo'})
      }
    })
  }


	
  const openNotification = (data: any) => {
		if(data.notice_type === 'refund') {
		  const key = `open${Date.now()}`;
		  const btn = (
		    <Button 
					type="primary" 
					onClick={() => {
						api.destroy();
						if(pathname === '/mine/aftersales/detail') {
							window.location.reload()
						}else {
							navigate(`/mine/aftersales/detail?merchant_id=${data.merchant_id}&inquiry_id=${data.inquiry_id}&refund_id=${data.refund_id}`)
						}
					}}>
					{intl.formatMessage({ id: '查看详情' })}
				</Button>
		  );
		  let refund_status = data.refund_status
		  let message;
		  if(refund_status === 1) {
		    message = intl.formatMessage({ id: '售后订单商家已同意！' })
		  }else {
		    message = intl.formatMessage({ id: '售后订单商家已拒绝！' })
		  }
		  api.open({
		    message: message,
		    duration: null,
		    description:(
		      <div>
		        <div className="mt10">
							{intl.formatMessage({ id: '创建时间' })}:{data.create_time}
						</div>
						<div className="mt10">
              {intl.formatMessage({ id: '处理时间' })}:{data.send_time}
						</div>
		      </div>
		    ),
		    btn,
		    key,
		    // onClose: close,
		  });
		}
    if(data.notice_type === 'order_quote_finished' || data.notice_type === 'order_wait_send') {
      const key = `open${Date.now()}`;
      const btn = (
        <Button type="primary" onClick={
					() => {
						if(window.location.pathname === '/mine/order/detail') {
						  window.location.reload()
						}else {
						  api.destroy(); 
							navigate(`/mine/order/detail?merchant_id=${data.merchant_id}&inquiry_id=${data.enquiry_info.enquiry_id}`)
						}
					}
				}
				>{intl.formatMessage({ id: '查看详情' })}</Button>
      );
      let inquiry_status = data.inquiry_status
      let car_info = data.car_info 
      let message;
      if(inquiry_status === 5) {
        message = intl.formatMessage({ id: '订单已报价，请尽快处理！' })
      }else {
        message = intl.formatMessage({ id: '订单已发货，请注意查收！' })
      }
      api.open({
        message: message,
        duration: null,
        description:(
          <div>
            {
              car_info ? (
                <div className="flex-s-c">
                  {car_info.brand_logo ? <img style={{ height: 20, border: '1px solid #f6f6f6' }} src={car_info.brand_logo} /> : ''}
                  {car_info.market_name}
                </div>
              ): null
            }
            <div className="mt20">{intl.formatMessage({ id: '创建时间' })}：{data.order_create_time}</div>
            {
              inquiry_status === 5 ? (
                <div className="mt10">{intl.formatMessage({ id: '报价时间' })}：{data.quote_time}</div>
              ): inquiry_status === 8 ? (
                <div className="mt10">{intl.formatMessage({ id: '发货时间' })}：{data.send_time}</div>
              ): null
            }
          </div>
        ),
        btn,
        key,
        // onClose: close,
      });
    }
    if(data.notice_type === 'user_verify') {
      const key = `open${Date.now()}`;
      const btn = (
        <Button type="primary" onClick={() => {
          if(window.location.pathname === '/mine/company') {
            window.location.reload()
          }else {
            api.destroy(); navigate(`/mine/company`)
          }
        }
        }>{intl.formatMessage({ id: '前往处理' })}</Button>
      );
      let phone = data.phone
      let _phone = phone.slice(-4)
      let message = `尾号${_phone}用户，提交了认证审核！`
      let time = data.create_time
      api.open({
        message: intl.formatMessage({ id: '审核单' }),
        duration: null,
        description:(
          <div>
            <div style={{color: '#333333', fontSize: 14}}>{message}</div>
            <div style={{marginTop: 8, color: '#888888', fontSize: 14}}>{time}</div>
          </div>
        ),
        btn,
        key,
        // onClose: close,
      });
    }
  };

  const getIm = (uid: string) => {
    let params = {
      uid
    }
    accountApi.getIm(params).then((res: any) => {
      if(res.code === 1) {
        let item = res.data
        let _window: any = window
        _window.SDK.NIM.getInstance({
          appKey: item.app_key,
          account: item.accid,
          token: item.token,
          onsessions: function(sessions: any){
            console.info('sessions==========')
            console.info(sessions)
          },
          onconnect: function () {
            console.info('SDK 连接成功')
            
            
          },
          onmsg: function (msg: any) {
            console.log(msg)
            console.log(JSON.parse(msg.text))
            openNotification(JSON.parse(msg.text))
          }
        })
      }
    })
  }

  let minHeight = `calc(100vh - ${pageType === '1' || pageType === '3' ? '150px' : '70px'})`
  return (
    <div className={styles['main']}>
      {contextHolder}
      <MainContext.Provider value={{store, setStore}}>
        {/* <ConfigProvider
          theme={{
            token: {
              colorPrimary
            }
          }}
        > */}
          <Header/>
          <div style={{ minHeight }}>
            <AliveScope>
              {
                other.includes(pathname) || pathname.includes("/morepage") ? (
                  <Router key={pathname + vinkey} />
                ): <Router  />
              }
            </AliveScope>
          </div>
          {
            token && pageType !== '1' ? <Sider/> : null
          }
          {
            pathname !== '/epc/vin/detail' ? (
              <Footer/>
            ): null
          }
          
        {/* </ConfigProvider> */}
      </MainContext.Provider>
    </div>
  )
}

export default Main