import React, { useEffect, useState } from 'react'

import styles from './index.module.scss'
import { useIntl } from 'react-intl'
import { Button, message, QRCode } from 'antd'
import html2canvas from 'html2canvas'
// import domToImage from 'dom-to-image'
import orderApi from '@/api/orderApi'
import { Spin } from 'antd/lib'






const Invitation: React.FC<any> = (props) => {
	
	const intl = useIntl()
	let host: any = window.location.host.includes("https") ? window.location.host : 'https://' + window.location.host
	let src1 = host + "/h1.jpg"
	let src2 = host + "/h2.jpg"
	const [activeSrc, setActiveSrc] = useState<any>(src1)
	
	const [code, setCode] = useState<any>("")
	const [distributorInfo, setDistributorInfo] = useState<any>({})
	
	const [downloadLoading1, setDownloadLoading1] = useState<boolean>(false)
	const [downloadLoading2, setDownloadLoading2] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)
	
	
	useEffect(() => {
		// imgUrlToBase64(src1)
		console.log()
		
		getDistributorInfo()
	},[])
	
	useEffect(() => {
		setLoading(true)
		setTimeout(() => {
			setLoading(false)
		},500)
	},[activeSrc])
	
	const getDistributorInfo = () => {
		orderApi.getDistributorInfo().then((res: any) => {
			if(res.code === 1) {
				setCode(res.data.invitation_code)
				setDistributorInfo(res.data)
			}
		})
	}
	
	const copy = (e: any, type?: any) => {
		let title = type === 'code' ? code : `${host}/register?invitation_code=${code}`
		
		e.stopPropagation()
		let oDiv = document.createElement('textarea')
		oDiv.value = title
		oDiv.innerHTML = title
		document.body.appendChild(oDiv)
		oDiv.select()
		document.execCommand("Copy")
		document.body.removeChild(oDiv)
		message.success(intl.formatMessage({ id: '复制成功！' }))
	}
	
	const imgUrlToBase64 = (url: any) => {
		const image: any = new Image()
		image.crossOrigin = "Anonymous"
		image.src = url
		let result = ""
		console.log(image)
		image.onload = () => {
			console.log(1111)
			let canvas: any = document.createElement("canvas")
			canvas.width = image.width
			canvas.height = image.height
			let ctx: any = canvas.getContext("2d")
			ctx.drawImage(image, 0, 0, image.width, image.height)
			let dataUrl: any = canvas.toDataURL("image/png")
			console.log(dataUrl)
			result = dataUrl
		}
		// return result
	}
	
	const download = (type?: any) => {
		
		  
		let dom: any = document.getElementById(type)
		let width = dom.offsetWidth
		let height = dom.offsetHeight
		
		if(type === 'aimg') { 
			setDownloadLoading1(true)
		}else {
			setDownloadLoading2(true)
		}
		
		html2canvas(dom,{
			width,
			height,
			scale: 3,
			backgroundColor: "#ffffff", //背景颜色 为null是透明
			// dpi: window.devicePixelRatio * 2,
			useCORS: true,
			allowTaint: true
		}).then((canvas: any) => {
			setDownloadLoading1(false)
			setDownloadLoading2(false)
			console.log(canvas.toDataURL())
			let url = canvas.toDataURL()
			// setImgloading(false)
			if(type === 'aimg' || true) {
				var a = document.createElement("a"); //创建一个a标签 用来下载
				a.download = `${code || 'new'}.png`
				var event = new MouseEvent("click"); //增加一个点击事件
				//如果需要下载的话就加上这两句
				a.download = `${code || 'new'}`
				a.href = url;//此处的url为base64格式的图片资源
				a.dispatchEvent(event); //触发a的单击事件 即可完成下载
			}
		})
	}
	
	
	return (
		<div className={styles['invitation']}>
			<div className={styles['left']}>
				<div className={styles['tab'] + " " + `${activeSrc === src1 ? styles['active'] : ''}`} onClick={() => setActiveSrc(src1)}>
					<img className={styles['img']} src={src1} />
				</div>
				<div className={styles['tab'] + ' mt10 ' + `${activeSrc === src2 ? styles['active'] : ''}`} onClick={() => setActiveSrc(src2)}>
					<img className={styles['img']} src={src2} />
				</div>
			</div>
			<div className={styles['right']}>
				<Spin spinning={loading}>
					<div className={styles['aimg']} id="aimg">
						<img src={activeSrc} crossOrigin="anonymous" style={{ height: '100%' }} />
						{
							activeSrc === src1 ? (
								<>
									<div className={styles['name1']}>
										{distributorInfo.name}
									</div>
									<div className={styles['qrcode1']} id="qrcode">
										<QRCode value={`${host}/register?invitation_code=${code}`} size={250} ></QRCode>
									</div>
									<div className={styles['code1']}>
										{code}
									</div>
									<div className={styles['phone1']}>
										{distributorInfo.phone}
									</div>
									<div className={styles['webset1']}>
										{host}
									</div>
								</>
							): (
								<>
									<div className={styles['name2']}>
										{distributorInfo.name}
									</div>
									<div className={styles['qrcode2']} id="qrcode">
										<QRCode value={`${host}/register?invitation_code=${code}`} size={220} ></QRCode>
									</div>
									<div className={styles['code2']}>
										{code}
									</div>
									<div className={styles['phone2']}>
										{distributorInfo.phone}
									</div>
									<div className={styles['webset2']}>
										{host}
									</div>
								</>
							)
						}
						
					</div>
				</Spin>
				
			</div>
			
			
			<div className={styles['tools']}>
				<Button className="mr20" type="primary" onClick={(e: any) => copy(e, 'code')}>{intl.formatMessage({ id: '复制邀请码' })}</Button>
				<Button className="mr20" type="primary" onClick={(e: any) => copy(e, "url")}>{intl.formatMessage({ id: '复制邀请链接' })}</Button>
				<Button loading={downloadLoading1 ? true : false} className="mr20" type="primary" ghost onClick={() => download('aimg')}>{intl.formatMessage({ id: '下载海报' })}</Button>
				<Button loading={downloadLoading2 ? true : false} type="primary" ghost onClick={() => download('qrcode')}>{intl.formatMessage({ id: '下载二维码' })}</Button>
			</div>
		</div>
	)
}

export default Invitation
