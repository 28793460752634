import React, { useEffect } from 'react'
import RcUpload from 'rc-upload'
import { Spin } from 'antd'
import { compress, dataURItoBlob } from '../../utils/utils'
interface RcFile extends File {
  uid: string;
}
type UploadRequestHeader = Record<string, string>;
interface PropsIn {
  parse?: any,
  loading?: boolean,
  setLoading?: Function,
  action: string,
  name?: string,
  data?: Record<string, unknown> | ((file: string | Blob | RcFile) => Record<string, unknown>) | undefined,
  headers?: UploadRequestHeader | undefined,
  multiple?: boolean,
  cancelClick?: boolean,
  beforeUpload?: (file: File, FileList: File[]) => Promise<any>,
  start: (response: Object) => void,
  success: (response: Object, file: File, xhr: Object) => void,
  error: (response: Object) => void,
  [propName: string]: any
}

let pasteUn = false

const Upload: React.FC<PropsIn> = (props) => {
  const { parse, loading, setLoading, action, name, data, headers, multiple, cancelClick, beforeUpload, start, success, error, children } = props

  useEffect(() => {
    if(parse){
      parseUpdate()
      return unmount
    }
  }, [])

  const unmount = () => {
    if(pasteUn){
      window.removeEventListener('paste', update)
    }
  }
  const parseUpdate = () => {
    window.removeEventListener('paste', update)
    window.addEventListener('paste', update)
    pasteUn = true
  }
  const update = (event: any) => {
    let clipboardData = event.clipboardData || event.originalEvent.clipboardData
    if ( !(clipboardData && clipboardData.items) ) {
      return;
    }
    let f = null;
    for (let i = 0, len = clipboardData.items.length; i < len; i++) {
      let item = clipboardData.items[i];
      if (item.type.indexOf('image') !== -1) {
        f = item.getAsFile();
      }
    }
    if(f) {
      fileReaderupdateImg(f)
    }
  }
  const fileReaderupdateImg = (file: any) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (e: any) => {
      let img: any = new Image()
      img.src = e.target.result
      img.onload = () => {
        let data = compress(img)
        let blob = dataURItoBlob(data)
        let formData = new FormData()
        formData.append((name || 'file'), blob)
        setLoading && setLoading(true)
        parse(formData).then((result: any)=>{
          setLoading && setLoading(false)
          success(result, file, {})
        })
      }
    }
  }
  
  return (
    <RcUpload
      action={action}
      name={name || 'file'}
      data={data || {}}
      headers={headers}
      multiple={multiple}
      beforeUpload={beforeUpload}
      onStart={start}
      onSuccess={success}
      onError={error}
      openFileDialogOnClick={cancelClick ? false : true}
    >
      <Spin spinning={loading || false}>
        {children}
      </Spin>
    </RcUpload>
  )
}

export default Upload