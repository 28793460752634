import Vin from './Vin'
import VinDetail from './VinDetail'
import VinDetailPage from './VinDetailPage'
import PartDetail from './PartDetail'
import PartSearch from './PartSearch'
import SearchPartInput from './SearchPartInput'
import PartSearchPage from './PartSearchPage'
import Search from './Search'
import Breadcrumb from './Breadcrumb'
import Goods from './Goods'

export default Vin
export {
  VinDetailPage,
  VinDetail,
  PartDetail,
  PartSearch,
  SearchPartInput,
  PartSearchPage,
  Search,
  Breadcrumb,
  Goods
}