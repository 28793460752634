import axios from "axios";

const listApi: any = {}

listApi.ocrPath = '/v1/api/web/parts/recognition/vin'

listApi.ocrImg = (params: {}): Promise<{}>=>{
  return axios.post(`${listApi.ocrPath}`, params)
}
listApi.getVinHistory = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/web/parts/history/vin', {params})
}
listApi.getOeHistory = (params: {}): Promise<{}> => {
	return axios.get("/v1/api/web/parts/history/goods", {params})
}
listApi.searchOeCars = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/web/parts/oe/search', params)
}
listApi.searchVinCars = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/web/parts/vin/cars', {params})
}
// 获取零件的列表
listApi.partList = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/web/parts/search/list', params)
}
// 获取零件列表中详情
listApi.partListPrice = (params: {}, headers?: {}): Promise<{}> => {
  return axios.post('/v1/api/webcst/goods', params, {
    headers
  })
}
// 获取零件列表中价格
listApi.partsPrice = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/webcst/goods/price', params)
}
// 获取零件详情
listApi.getPartInfo = (params: {}, headers?: {}): Promise<{}> => {
  return axios.get('/v1/api/web/parts/search/info', {
    params,
    headers
  })
}
listApi.getPartInfoCars = (params: {}, headers?: {}): Promise<{}> => {
  return axios.get('/v1/api/web/parts/search/info/cars', {params, headers})
}
listApi.getClassList = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/cstnl/categories', {params})
}
listApi.getGroupOePart = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/webcst/goods/oes', params)
}
listApi.getStock = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/webcst/batch/search/stock', params)
}
listApi.createInquiry = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/webcst/inquiry', params)
}
listApi.getNoLoginAllGoods = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/cstnl/all_goods', params)
}
listApi.getAllGoods = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/webcst/all_goods', params)
}
listApi.getCjhGoodsPrice = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/webcst/goods/price2', params)
}
listApi.getFreeCjhGoodsPrice = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/cstnl/goods/price2', params)
}
listApi.getOeList = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/webcst/batch/oe/stock', params)
}
listApi.getFreeOeList = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/cstnl/batch/oe/stock', params)
}
listApi.searchGroupOeCar = (params: {}, headers?: {}): Promise<{}> => {
  return axios.get('/v1/api/web/parts/oe/search/more', {
    params,
    headers: headers ? headers : {}
  })
}
listApi.searchCjhPart = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/webcst/vin/stock', params)
}
listApi.searchNewCjhPart = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/webcst/vin/stock2', params)
}
listApi.searchNewFreeCjhPart = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/cstnl/vin/stock2', params)
}
listApi.getStockList = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/stock/list', {params})
}
listApi.getOeBrand = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/oe/brand', {params})
}
listApi.getAdZoneGoods = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/ad/goods', {params})
}
listApi.getAdZoneGoodsTwo = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/ad/goods2', {params})
}
listApi.getBreakImg = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/oe/break_img', {params})
}
listApi.getGroupBreakImg = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/break_img', {params})
}


listApi.getCarsBrand = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/web/parts/search/info/cars/brand', {params})
}
listApi.getCarsInfo = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/web/parts/search/info/cars', params)
}

listApi.delInquiry = (params: {}): Promise<{}> => {
  return axios.delete(`/v1/api/webcst/inquiry/delete`, {
    data: params
  })
}

export default listApi