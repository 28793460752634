import React, { useState, useEffect, useContext } from 'react'
import MainContext from '@/stores/stores'
import { VinDetail } from '@/pages/Epc'
import { Modal, Spin } from "antd"

import vinApi from '@/api/epcApi'
import css from './vin.module.scss'
import { useIntl } from 'react-intl'

interface PropsIn {
  keyword: string,
  vin?: string,
  mcid: string,
  brandcode: string,
  title?: string,
  cancelFn?: Function
}

const PartSearch: React.FC<PropsIn> = (props) => {
  const { keyword, mcid, vin, brandcode, cancelFn, title='' } = props
  const { store, setStore } = useContext(MainContext)
  const intl = useIntl()
  const [ partVisible, setPartVisible ] = useState<boolean>(true)
  const [ partListVisible, setPartListVisible ] = useState<boolean>(false)
  const [ active, setActive ] = useState<any>({})
  const [ data, setData ] = useState<any>([])
  const [ total, setTotal ] = useState<any>(0)
  const [ loading, setLoading ] = useState<any>(false)
  
  useEffect(()=>{
    if(keyword){
      partSearch()
    }
  }, [keyword])
  const partSearch = () => {
    const param = {
      mcid,
      vin: vin || '',
      brandCode: brandcode,
      search_key: keyword,
      search_type: 'pid'
    }
    setLoading(true)
    vinApi.searchKey(param).then((result: {code: number, data: object[]})=>{
      let t = 0
      setLoading(false)
      if(result.code === 1 && result.data){
        let temp: any = {}
        result.data.forEach((item: any)=>{
          if(!temp[item.mid]){
            temp[item.mid] = []
          }
          if(temp[item.mid].findIndex((it: any) => it.pid === item.pid) === -1){
            t++
            temp[item.mid].push(item)
          }
        })
        setData(temp)
        console.log(JSON.stringify(temp))
        setTotal(t)
        if(result.data.length === 1){
          showList(result.data[0])
        }
      }
    })
  }
  const showList = (item: object) => {
    setActive(item)
    setPartVisible(false)
    setPartListVisible(true)
  }
  const backFn = () => {
    setPartVisible(true)
    setPartListVisible(false)
  }
  const cancel = (val: boolean) => {
    setPartVisible(val)
    cancelFn && cancelFn({})
  }
  const cancelDetail = (val: boolean) => {
    setPartListVisible(val)
    cancelFn && cancelFn({})
  }
  return (
    <>
      <Modal
        title={(vin ? (vin + ' > ') : '') + title + keyword}
        open={partVisible}
        onCancel={()=>cancel(false)}
        width={1200}
        footer={null}
      >
        <Spin spinning={loading} indicator={<img src="https://cdns.007vin.com/img/autorepair/cst/car_loading.gif"/>} wrapperClassName={css['list-spin']}>
          <div className={css['part-search']}>
            {
              total ? <h5>为您找到 {keyword} 相关结果共<em>{total}</em>个</h5> : null
            }
            {
              !loading && !total ? <div className={css.nodata}><h4>未找到 {keyword} 相关零件</h4> <button onClick={()=>cancel(false)}>{intl.formatMessage({ id: "主组查询" })}</button></div> : null
            }
            <div className={css['content']}>
              {
                Object.keys(data).map((key: any, i: number)=>{
                  const it = data[key][0]
                  return (
                    <div className={css.bg} key={i}>
                      <h5><strong>{it.num}</strong>{` ${it.maingroupname} > `}<strong>{it.mid}</strong> {it.subgroupname}</h5>
                      {
                        data[key].map((item: any, index: number)=>{
                          return (
                            <div className={css['item']} key={index} onClick={()=>showList(item)}>
                              <div className={css['l']}>
                                <img src={item.url} alt={item.pid} />
                              </div>
                              <div className={css['r']}>
                                <h6>{item.label}</h6>
                                <p>
                                  <label>OE</label>
                                  {item.pid}
                                </p>
                                <p>
                                  <label>{intl.formatMessage({ id: "主组" })}</label>
                                  {item.maingroupname}
                                </p>
                                <p>
                                  <label>{intl.formatMessage({ id: "分组" })}</label>
                                  {item.mid} {item.subgroupname}
                                </p>
                                <p>
                                  <label>{intl.formatMessage({ id: "型号" })}</label>
                                  {item.model}
                                </p>
                                <p>
                                  <label>{intl.formatMessage({ id: "备注" })}</label>
                                  {item.remark}
                                </p>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  )
                })
              }
            </div>
          </div>
        </Spin>
      </Modal>
      <Modal
        title={`${vin ? vin + ' > ' : ''} ${title ? (title + ' | ') : ''} ${active.num} ${active.maingroupname} > ${active.mid} ${active.subgroupname}`}
        open={partListVisible}
        onCancel={()=>cancelDetail(false)}
        width={'90%'}
        footer={null}
      >
        <VinDetail
          type='partSearch'
          vin={vin}
          brandcode={active.brandCode}
          mcid={active.mcid}
          num={active.num}
          mid={active.mid}
          subgroup={active.subgroup}
          pid={active.pid}
          backFn={total > 1 ? backFn : undefined}
        />
      </Modal>
    </>
  )
}

export default PartSearch;