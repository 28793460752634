import React, { useState, useEffect, useRef, useContext } from 'react'
import MainContext from '@/stores/stores'
import { PartSearch } from '@/pages/Epc'
import vinApi from '@/api/epcApi'
import css from './vin.module.scss'
import { useIntl } from 'react-intl'

interface PropsIn {
  vin: string,
  mcid: string,
  brandcode: string,
}

const SearchPartInput: React.FC<PropsIn> = (props) => {
  const { vin, mcid, brandcode } = props
  const { store } = useContext(MainContext)
  const intl = useIntl()
  const { param } = store
  const [ partKey, setPartKey ] = useState<any>('')
  const [ searchKey, setSearchKey ] = useState<any>('')
  const [ partSearchkey, setPartSearchkey ] = useState<any>(0)
  const [ data, setData ] = useState<any>([])
  const [ visible, setVisible ] = useState<any>(false)
  let isPinyin = useRef<boolean>(false)
  useEffect(()=>{
    if(searchKey){
      setPartSearchkey(partSearchkey + 1)
    }
  }, [searchKey])

  const searchHot = (key?: string) => {
    let param: any = {
      brandCode: brandcode,
      mcid,
      vin
    }
    setData([])
    if(key){
      param.search_key = key
      param.search_type = 'pid'

      vinApi.searchHot(param).then((result: any)=>{
        if(result.code === 1){
          let data:any = []
          result.data.forEach((item: any)=>{
            let name = item
            let title = item
            if(typeof item !== 'string'){
              name = item.pid + ' ' + item.orignal_name
              title = item.pid
            }
            let reg = RegExp('(' + key + ')')
            data.push({
              title,
              name: name.replace(reg, '<span style="color: #2a98fe">$1</span>')
            })
          })
          setData(data)
        }
      })
    }else {
      // commonApi.searchDefaultHot(param).then((result: any)=>{
      //   if(result.code === 1){
      //     setData(result.data)
      //   }
      // })
      setData([])
    }
  }

  const focus = () => {
    setVisible(true)
    if(!data.length){
      searchHot()
    }
  }
  const blur = () => {
    setTimeout(()=>{
      setVisible(false)
    }, 300)
  }
  const change = (value?: string) => {
    window.sessionStorage.setItem('searchKey', (value || ''))
    setPartKey(value)
    setTimeout(()=>{
      if(!isPinyin.current){
        searchHot(value)
      }
    })
  }
  const cancelFn = () => {
    setSearchKey('')
    setPartSearchkey(0)
  }
  return (
    <>
      <div className={css.searchPartInput}>
        <input type="text" value={partKey} placeholder={intl.formatMessage({ id: "输入零件原厂名／零件号" })} onChange={(e)=>change(e.target.value)} onFocus={()=>focus()} onBlur={()=>blur()} onCompositionStart={()=>{isPinyin.current = true}} onCompositionEnd={()=>{isPinyin.current = false}} onKeyUp={(e)=>{e.keyCode === 13 && setSearchKey(partKey)}} />
        <i className="iconfont icon-sousuo" onClick={()=>setSearchKey(partKey)} />
        {
          visible ? (
            <div className={css.hot}>
              <ul>
                {
                  data.map((item: any, index: number)=>{
                    return (
                      <li key={index} dangerouslySetInnerHTML={{__html: item.name}} onClick={()=>setSearchKey(item.title)}></li>
                    )
                  })
                }
              </ul>
            </div>
          ): null
        }
      </div>
      {
        partSearchkey ? (
          <PartSearch
            key={partSearchkey}
            keyword={searchKey}
            mcid={mcid}
            brandcode={brandcode}
            vin={vin}
            cancelFn={cancelFn}
          />
        ): null
      }
    </>
  )
}

export default SearchPartInput