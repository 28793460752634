import axios from 'axios'

const accountApi: any = {}


accountApi.getIm = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/wechat/im/info', {params})
}
accountApi.getLangData = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/openapi/system/lang/pack', {params})
}
accountApi.getProtocol = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/cstnl/protocol', {params})
}
accountApi.getSysiting = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/cstnl/index/style', {params})
}

accountApi.getSmsCode = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/web/user/smscode/phone', {params})
}
accountApi.getEmailCode = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/web/user/smscode/email', {params})
}
accountApi.login = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/web/user/login', params)
}
accountApi.smsLogin = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/web/user/login/sms', params)
}
accountApi.llqLogin = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/web/user/login/007', params)
}
accountApi.llqTokenLogin = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/web/user/login/007/token', params)
}
accountApi.partboundLogin = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/openapi/partsBond/user/info', {params})
}
accountApi.getQrcode = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/cstnl/007/qrcode', {params})
}
accountApi.getLoginStatus = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/web/user/login/007app', {params})
}
accountApi.logout = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/web/user/loginout', params)
}
accountApi.register = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/web/user/register', params)
}
accountApi.resetPwd = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/web/user/update/pwd', params)
}
accountApi.getCompanyList = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/web/user/comp/query', {params})
}
accountApi.getUserInfo = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/user/info', {params})
}
accountApi.updateUserInfo = (params: {}): Promise<{}> => {
  return axios.put('/v1/api/web/user/profile/detail', params)
}


// 获取品牌数据
accountApi.getSelectBrandList = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/web/merchant/menu/brand_code', {params})
} 

// 选择moldal数据
accountApi.modelSelect = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/web/parts/menu/cars', {params})
}

accountApi.getHotList = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/cstnl/goods/hot', {params})
}

// 首页数据

accountApi.getHomeData = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/cstnl/index', {params})
}
accountApi.getStoreAddress = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/cstnl/merchant/address', {params})
}
accountApi.getStoreAddressNew = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/cstnl/merchant/address2', {params})
}

accountApi.getHomeDataTwo = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/cstnl/index2', {params})
}

// 更多页面数据
accountApi.getMoreData = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/cstnl/index/common', {params})
}

// 获取导航
accountApi.getNavgation = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/cstnl/navigation', {params})
}
accountApi.getNavgationNew = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/cstnl/navigation2', {params})
}

accountApi.getMorePage = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/cstnl/other_page', {params})
}
accountApi.getMorePageNew = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/cstnl/other_page2', {params})
}

// 获取2级分类数据
accountApi.getSecondClass = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/web/user/goods/category', {params})
}

accountApi.getAdZone = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/cstnl/ad/list', {params})
}

accountApi.getIsCst = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/cstnl/base', {params})
}
accountApi.toApplyVertify = (params: {}): Promise<{}> => {
  return axios.post('/v1/api/webcst/user/verify/apply', params)
}

accountApi.getDistributorInfo = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/webcst/distributor/info', {params})
}
accountApi.getHomeDataThree = (params: {}): Promise<{}> => {
  return axios.get('/v1/api/cstnl/index3', {params})
}
export default accountApi