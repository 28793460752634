import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import MainContext from '@/stores/stores'
import { message, Input, Radio, Pagination, Spin } from "antd"
import Upload from '@/components/Upload'
import { getUrlParams } from '@/utils/utils'
import vinApi from '@/api/epcApi'
import styles from './vin.module.scss'
import { useIntl } from 'react-intl'



const Search: React.FC = () => {
  const { id } = getUrlParams()
  const navigate = useNavigate()
  const intl = useIntl()
  const { store } = useContext(MainContext)
  const [vin, setVin] = useState<string>('')
  const [brand, setBrand] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [history, setHistory] = useState<any>([])
  const [page, setPage] = useState<any>({
    page: 1,
    size: 10,
    total: 0
  })

  const [imgLoading, setImgLoading] = useState<boolean>(false)
  const brands: any = {
    all: {
      key: intl.formatMessage({ id: "全部" }),
      tips: intl.formatMessage({ id: "输入OE号或17位车架号(支持拖拽或粘贴图片识别)" })
    },
    bmw: {
      key: intl.formatMessage({ id: "宝马" }),
      tips: intl.formatMessage({ id: "输入宝马后7位或17位车架号" })
    },
    benz: {
      key: intl.formatMessage({ id: "奔驰" }),
      tips: intl.formatMessage({ id: "输入奔驰后8位或17位车架号" })
    },
    land_rover: {
      key: intl.formatMessage({ id: "路虎捷豹" }),
      tips: intl.formatMessage({ id: "输入路虎捷豹后8位或17位车架号" })
    },
    maserati: {
      key: intl.formatMessage({ id: "玛莎拉蒂" }),
      tips: intl.formatMessage({ id: "输入玛莎拉蒂后7位或17位车架号" })
    },
    zhongqi: {
      key: intl.formatMessage({ id: "重汽" }),
      tips: intl.formatMessage({ id: "输入重汽后8位或17位车架号" })
    },
    fawjiefang: {
      key: intl.formatMessage({ id: "解放" }),
      tips: intl.formatMessage({ id: "输入解放后8位或17位车架号" })
    },
    shanqi: {
      key: intl.formatMessage({ id: "陕汽" }),
      tips: intl.formatMessage({ id: "输入陕汽后8位或17位车架号" })
    }
  }
  // useEffect(()=>{
  //   getHistory()
  // }, [])
  useEffect(()=>{
    if(localStorage.getItem('token')) {
      getHistory()
    }
  }, [page.page])

  useEffect(()=>{
    if(localStorage.getItem('token')) {
      if(brand){
        if(page.page === 1){
          getHistory()
        }else {
          pageChange(1, page.size)
        }
      }
    }
  }, [brand])


  const submit = () => {

    let url = `/epc/vin?vin=${vin}`
    if(brand === 'all' || !brand){
      if(vin.length !== 17){
        url = `/epc/part?pid=${vin}`
      }
    }else {
      url += `&brandcode=${brand}`
    }

    navigate(url)
  }
  const getHistory = () => {
    let param =  JSON.parse(JSON.stringify(page))
    delete param.total
    if(brand && brand !== 'all'){
      param.brandCode = brand
    }
    setLoading(true)
    vinApi.getHistory(param).then((result: any)=>{
      setLoading(false)
      if(result.code === 1){
        page.total = result.data.total
        setPage(page)
        result.data.data.forEach((item: any)=>{
          const k = item.keyword.split('\n')
          if(item.query_type === 'vin'){
            item.vin = k[0]
          }else {
            item.pid = k[0]
          }
          
          item.title = k[1]
          item.brandcode = item.brandCode || k[2]
        })
        setHistory(result.data.data)
      }
    })
  }
  const pageChange = (p: any, pageSize: any) => {
    let temp = JSON.parse(JSON.stringify(page))
    temp.page = p
    if(pageSize){
      temp.size = pageSize
    }

    setPage(temp)
  }

  const beforeUpload = (file: File, FileList: File[]) => {
    return new Promise((resolve, reject) => {
      resolve(file)
    })
  }
  const start = (result: any) => {
    setImgLoading(true)
  }
  const success = (result: any, file: File) => {
    if(result.code === 1){
      setVin(result.data.vin)
    }else {
      message.warning(result.msg || intl.formatMessage({ id: "解析错误，请上传清晰图片！" }))
    }
    setImgLoading(false)
  }
  const error = (result: any) => {
    message.warning('error')
    setImgLoading(false)
  }
  return (
    <Upload
      cancelClick
      parse={vinApi.ocr}
      loading={imgLoading}
      setLoading={setImgLoading}
      action={vinApi.ocrPath}
      name="file"
      beforeUpload={beforeUpload}
      start={start}
      success={success}
      error={error}
    >
      <div className={styles.vinSearch}>
        <img src={store.logo || ""} alt="" style={{maxWidth: 200, marginBottom: 10}} />
        <Radio.Group onChange={(e)=>setBrand(e.target.value)} value={brand || 'all'}>
          {
            Object.keys(brands).map((key)=>{
              return (
                <Radio value={key}>{brands[key].key}</Radio>
                )
            })
          }
        </Radio.Group>
        <div className={styles.vinSearchInput}>
          {
            vin.length ? (
              <span className={styles.count}>{vin.length}</span>
            ): null
          }
          <Input.Search
            placeholder={brands[brand || 'all'].tips}
            enterButton={intl.formatMessage({ id: "查询" })}
            size="large"
            value={vin}
            onChange={(e)=>setVin(e.target.value.replace(/[\W]/g, "").toUpperCase())}
            // count={{
            //   show: true,
            //   strategy: (txt) => txt.length,
            // }}
            onSearch={()=>submit()}
          />
        </div>
        <Spin spinning={loading}>
          {
            history.length ? (
              <div className={styles.history}>
                <h5>{intl.formatMessage({ id: "历史记录" })}</h5>
                <div className={styles.content}>
                  {
                    history.map((item: any)=>{
                      let url = `/epc/part?pid=${item.pid}&brandcode=${item.brandcode}`
                      if(item.query_type === 'vin'){
                        url = `/epc/vin?vin=${item.vin}&brandcode=${item.brandcode}`
                      }
                      return (
                        <div className={styles.item} onClick={()=>navigate(url)}>
                          <span>{item.vin || item.pid}</span>
                          {/* <span>{item.title}</span> */}
                          <span dangerouslySetInnerHTML={{ __html: item.title }} />
                        </div>
                      )
                    })
                  }
                  
                </div>
              </div>
            ): null
          }
          <Pagination hideOnSinglePage size="small" current={page.page} total={page.total} onChange={(page: number, pageSize: number)=>pageChange(page, pageSize)} />
        </Spin>
      </div>
    </Upload>
  )
}

export default Search