import React, { useState } from 'react'
import { Upload, App, Image } from 'antd'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import styles from './index.module.scss'

const UploadCon = (props) => {
  const { message } = App.useApp()
  const { name = 'files', action = "/v1/api/common/file/upload", img, setImg, tip = "上传", width = 160, height = 100, className = "", getData, disabled = false, loading = false, setLoading = () => {} } = props
  const intl = useIntl()
  const [voucherLoading, setVoucherLoading] = useState(false)

  const voucher = ({file}) => {
    if(file.status === 'uploading') {
      setVoucherLoading(true)
      setLoading(true)
    }
    if(file.status === 'done') {
      let res = file.response
      if(res.code === 1) {
        if(action === '/v1/api/cstnl/license/img/identify/v2') {
          getData && getData(res.data)
        }else {
          let data = file.response.data.files
          let key = Object.keys(data)[0]
          
          let url = data[key].url
          setImg && setImg(url)
          setVoucherLoading(false)
        }
        
      }else {
        setVoucherLoading(false)
      }
    }
  }

  const beforeUpload = (file) => {
    return new Promise((resolve, reject) => {
      // 图片压缩
      let reader = new FileReader()
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        let img = document.createElement('img');
        img.src = reader.result;
        img.onload = function () {
          let rate = 0.2;
   
          // 文件大小KB
          const fileSizeKB = Math.floor(file.size / 1024);
          console.log('fileSizeKB', fileSizeKB)
   
          let canvas = document.createElement('canvas');
          let context = canvas.getContext('2d');
          // 缩放比例，默认0.5
          if(fileSizeKB > 200) {
            // let targetW = (canvas.width = img.naturalWidth * rate);
            // let targetH = (canvas.height = img.naturalHeight * rate);
            let targetW = (canvas.width = img.naturalWidth);
            let targetH = (canvas.height = img.naturalHeight);
            context.clearRect(0, 0, targetW, targetH);
            context.drawImage(img, 0, 0, targetW, targetH);
          }else {
            let targetW = (canvas.width = img.naturalWidth);
            let targetH = (canvas.height = img.naturalHeight);
            context.clearRect(0, 0, targetW, targetH);
            context.drawImage(img, 0, 0, targetW, targetH);
          }
          
          // canvas.toBlob((blob) => {
          //   let imgFile = new File([blob], file.name, {type: file.type}); // 将blob对象转化为图片文件
          //   resolve(imgFile);
          // }, file.type, rate); // file压缩的图片类型
          canvas.toBlob((result) => resolve(result), file.type, rate);
        }
      }
 
      
    })
  };

  
  return (
    <Upload
      name={name}
      accept=".png, .jpg, .jpeg, .gif"
      beforeUpload={beforeUpload}
      disabled={disabled}
      action={action}
      showUploadList={false}
      onChange={voucher}
      openFileDialogOnClick={img ? false : true}
    >
      {
        img ? (
          <div className={styles['has-img'] + ' ' + className} style={{ width, height }}>
            <Image src={img} width={width} height={height} />
            {
              disabled ? null : <i className="iconfont icon-quxiao1"  onClick={() => { setImg && setImg("") }} />
            }
          </div>
        ): (
          <div className={styles['upload-area'] + ' ' + className} style={{ width, height }}>
            {voucherLoading || loading ? <LoadingOutlined rev={undefined} /> : <PlusOutlined rev={undefined} />}
            <div style={{ marginTop: 8, color: '#86909C' }}>{intl.formatMessage({ id: tip })}</div>
          </div>
        )
      }
    </Upload>
  )
}
export default UploadCon