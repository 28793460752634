import React, { useContext, useEffect, useState, useMemo } from 'react';
import { BrowserRouter } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { ConfigProvider } from 'antd';
import type { Locale } from 'antd/es/locale';
import Main from './pages/Main';
import ResInterceptors from './components/ResInterceptors';
import trMessages from './locale/tr'
import ruMessages from './locale/ru'
import enMessages from './locale/en'

import zhCN from 'antd/locale/zh_CN';
import enGB from 'antd/locale/en_GB'
import ruRu from 'antd/locale/ru_RU'
import zhHK from 'antd/locale/zh_HK'
import Tr from 'antd/locale/tr_TR'
import 'dayjs/locale/zh-cn';
import accountApi from './api/accountApi'
import axios from 'axios'
import { AliveScope } from 'react-activation'


let num: number = 1

const App = () => {
  const [messages, setMessages] = useState<any>({})
  const [lang, setLang] = useState<string>("zh")
  const langTemp: any = {
    'zh': {
      locale: 'zh-CN',
      antdLocale: zhCN,
      messages: {}
    },
    'en': {
      locale: 'en',
      antdLocale: enGB,
      messages: enMessages.messages
    },
    'ru': {
      locale: 'ru',
      antdLocale: ruRu,
      messages: ruMessages.messages
    },
    'cht': {
      locale: 'cht',
      antdLocale: zhHK,
      messages: {}
    },
    'tr': {
      locale: 'tr',
      antdLocale: Tr,
      messages: trMessages.messages
    }
  }
  useEffect(() => {
    let storageLang = window.localStorage.getItem('lang') ? window.localStorage.getItem('lang') : ''
    console.log("storageLang1111:", storageLang)
    if(storageLang) {
      window.localStorage.setItem('lang', storageLang)
      setLang(storageLang)
      getLangData(storageLang)
    }else {
      getLangList()
    }
  }, [])

  
  
  /* const initLang = (): any => {
    let lang: any = 'zh'
    if(window.localStorage.getItem('lang')) {
      lang = window.localStorage.getItem('lang')
    }else if(window.navigator.language.includes('zh')) {
      lang = 'zh'
    }else if(window.navigator.language.includes('en')) {
      lang = 'en'
    }else if(window.navigator.language.includes('ru')) {
      lang = 'ru'
    }else if(window.navigator.language.includes('HK') || window.navigator.language.includes('TW')) {
      lang = 'cht'
    }else if(window.navigator.language.includes('tr') || window.navigator.language.includes('TR')) {
      lang = 'tr'
    }
    num && getLangData(lang)
    window.localStorage.setItem('lang', lang)
    return lang
  } */
  const initLang = useMemo(() => {
    let storageLang = window.localStorage.getItem('lang') ? window.localStorage.getItem('lang') : ''
    if(storageLang) {
      return storageLang
    }else if(lang) {
      return lang
    }else if(window.navigator.language.includes('zh')) {
      return 'zh'
    }else if(window.navigator.language.includes('en')) {
      return 'en'
    }else if(window.navigator.language.includes('ru')) {
      return 'ru'
    }else if(window.navigator.language.includes('HK') || window.navigator.language.includes('TW')) {
      return 'cht'
    }else if(window.navigator.language.includes('tr') || window.navigator.language.includes('TR')) {
      return 'tr'
    }else {
      return 'zh'
    }
  }, [lang])
  const getLangList = () => {
    let params = {
      lang: localStorage.getItem('lang') || 'zh',
      domain: window.location.host
    }
    accountApi.getSysiting(params).then((res: any) => {
      let res1 = res.data
      if(res1.code === 1) {
        if(res1.data.languages && res1.data.languages.length) {
          let _lang = res1.data.languages[0]
          console.log("_lang:", _lang)
          window.localStorage.setItem('lang', _lang)
          setLang(_lang)
          getLangData(_lang)
        }
      }
    })
  }
  const getLangData = (lang: string) => {
    num = 0
    accountApi.getLangData({ xqpLang:  lang }).then((res: any) => {
      let { data } = res
      if(data.code === 1) {
        setMessages({...data.data, ...langTemp[lang].messages})
      }
    })
  }
  if(!Object.keys(messages).length) return null
  return (
    <BrowserRouter basename="/">
      <IntlProvider locale={langTemp[initLang].locale} messages={messages}>
        <ConfigProvider 
          locale={langTemp[initLang].antdLocale}
        >
          <ResInterceptors/>
					<Main/>
        </ConfigProvider>
      </IntlProvider>
    </BrowserRouter>
  );
}

export default App;
