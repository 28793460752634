import React, { useEffect, useContext } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import styles from './index.module.scss'
import cartIcon from '../../assets/img/cart_icon.png'
import orderApi from '../../api/orderApi'
import MainContext from '../../stores/stores'

const Sider: React.FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { store, setStore } = useContext(MainContext)
  useEffect(() => {
    let token = localStorage.getItem('token')
    if(token) {
      getCartList()
    }
  }, [])
  const getCartList = () => {
    orderApi.getCartList().then((res: any) => {
      if(res.code === 1) {
        let _data = res.data
        let length = 0
        Object.keys(_data).forEach((items: string) => {
          Object.keys(_data[items].goods_list).forEach((item: string) => {
            length += 1
          })
        })
        setStore({
          type: 'cartNum',
          value: length 
        })
      }
    })
  }
  const toGo = (url: string) => {
    navigate(url)
  }
  return (
    <div className={styles['sider-wrap']}>
      <div className={styles['sider-item']} onClick={() => toGo('/cart')}>
        <img src={cartIcon} alt="" />
        <span className={styles['sider-title']}>{intl.formatMessage({id: '购物车'})}({store.cartNum})</span>
      </div>
    </div>
  )
}
export default Sider